import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FaRegCircleXmark } from "react-icons/fa6";
import { HiDuplicate } from "react-icons/hi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { duplicateExams, fetchDataExams } from "../../store/slices/apiExamsSlice";


const DuplicateModal = ({ id }) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };


    const handleDeleteItem = async () => {
        try {
            await dispatch(duplicateExams(id)).unwrap();
            handleCloseModal();
        } catch (error) {
            console.error("Error duplicating exam:", error);
        }
    };


    return (
        <div>
            <button
                data-modal-target="popup-modal"
                data-modal-toggle="popup-modal"
                className="text-danger mx-1"
                onClick={handleOpenModal}

            >
                <HiDuplicate style={{ width: 20, height: 20 }} />{" "}
            </button>

            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="bg-gray-900 bg-opacity-10 absolute inset-0"></div>
                    <div className="relative bg-white rounded-lg shadow-lg">
                        <button
                            type="button"
                            className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 flex justify-center items-center"
                            onClick={handleCloseModal}
                        >
                            <FaRegCircleXmark style={{ width: 20, height: 20 }} />{" "}
                        </button>

                        <div className="p-6 text-center">
                            <div className="mx-auto  text-gray-400 w-12 h-12 dark:text-gray-200 mb-6">
                                <AiOutlineExclamationCircle
                                    className=""
                                    style={{ width: 70, height: 70 }}
                                />
                            </div>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                A jeni te sigurte qe deshironi ta duplifikoni kete test?
                            </h3>
                            <button
                                data-modal-hide="popup-modal"
                                type="button"
                                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                onClick={handleDeleteItem}
                            >
                                Po, vazhdo
                            </button>
                            <button
                                data-modal-hide="popup-modal"
                                type="button"
                                onClick={handleCloseModal}
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                            >
                                Jo, anulo
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DuplicateModal;
