import React, { useEffect } from 'react';
import { FiSettings } from "react-icons/fi";
import Path from "./routes/Path";
import { Sidebar, Navbar } from "./layouts";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  Categories,
  Dashboard,
  DrivingSchool,
  Exams,
  FinancialBalance,
  Literature,
  Login,
  Questions,
  QuestionsCategory,
  TextLiterature,
  Users,
  Package,
  UserDriveSchool,
  ListQuestionCategory,
  MovmentDriveSchool,
  Cards
} from "./pages";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from 'jwt-decode';
import { setUserRole } from './store/slices/userSlice';
import PrivateRoute from './routes/PrivateRoute';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const activeMenu = useSelector((state) => state.app.activeMenu);
  const screenSize = window.innerWidth;
  const navigate = useNavigate();
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  const dispatch = useDispatch();

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) {
      navigate('/login')
    }
    if (userToken) {
      const decodedToken = jwt_decode(userToken);
      dispatch(setUserRole(decodedToken.role));
      // navigate('/dashboard')
    }
  }, []);

  return (
    <div>
      <div className="flex relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
          <button
            type="button"
            className="text-4xl p-3 hover:drop-shadow-xl text-white"
            style={{ background: "#FFC500", borderRadius: "50%" }}
          >
            <FiSettings />
          </button>
        </div>
        {activeMenu && !isLoginPage ? (
          <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg z-50 bg-white">
            <Sidebar screenSize={screenSize} />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg ">
            <Sidebar screenSize={screenSize} />
          </div>
        )}
        <div
          className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu && !isLoginPage ? "md:ml-72 " : "flex-2"
            } `}
        >
          {!isLoginPage && (
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full z-40">
              <Navbar />
            </div>
          )}
          <ToastContainer />
          <div>
            <Routes>
              <Route path="/login" element={<Login />} />

              <Route
                path="/dashboard"
                element={<PrivateRoute roles={['admin', 'user']}>
                  <Dashboard />
                </PrivateRoute>}
              />
              <Route path="/users"
                element={
                  <PrivateRoute roles={['admin']}>
                    <Users />
                  </PrivateRoute>
                } />
              <Route
                path="/categories"
                element={
                  <PrivateRoute roles={['admin']}>
                    <Categories />
                  </PrivateRoute>
                }
              />
              <Route
                path="/exams"
                element={
                  <PrivateRoute roles={['admin']}>
                    <Exams />
                  </PrivateRoute>}
              />
              <Route
                path="/questions"
                element={
                  <PrivateRoute roles={['admin']}>
                    <Questions />
                  </PrivateRoute>
                }
              />
              <Route
                path="/drivingschools"
                element={
                  <PrivateRoute roles={['admin']}>
                    <DrivingSchool />
                  </PrivateRoute>
                }
              />
              <Route
                path="/questionscategory"
                element={
                  <PrivateRoute roles={['admin']}>
                    <QuestionsCategory />
                  </PrivateRoute>
                }
              />
              <Route
                path="/financialbalance"
                element={
                  <PrivateRoute roles={['admin']}>
                    <FinancialBalance />
                  </PrivateRoute>
                }
              />
              <Route
                path="/literature"
                element={
                  <PrivateRoute roles={['admin']}>
                    <Literature />
                  </PrivateRoute>
                }
              />

              <Route
                path='/textliterature'
                element={
                  <PrivateRoute>
                    <TextLiterature roles={['admin']} />
                  </PrivateRoute>
                }
              />
            
              <Route
                path="/package"
                element={
                  <PrivateRoute roles={['admin']}>
                    <Package />
                  </PrivateRoute>
                }
              />
               <Route
                path="/cards"
                element={
                  <PrivateRoute roles={['admin']}>
                    <Cards />
                  </PrivateRoute>
                }
              />
              <Route
                path='/users-driveschool'
                element={
                  <PrivateRoute roles={['user']}>
                    <UserDriveSchool />
                  </PrivateRoute>
                }
              />
              <Route
                path='/question-category'
                element={
                  <PrivateRoute  roles={['admin']}>
                    <ListQuestionCategory />
                  </PrivateRoute>
                }
              />

              <Route
                path='/movment'
                element={
                  <PrivateRoute  roles={['user']}>
                    <MovmentDriveSchool />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;



