import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";


export const fetchDataCard = createAsyncThunk("api/fetchDataCard", async () => {
  try {
    const response = await axiosInstace.get("/cards/getAllCards"); 
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const createDataCards = createAsyncThunk(
  "api/createDataCards",
  async (createDataCards) => {
    console.log(createDataCards);
    try {
      const response = await axiosInstace.post("/cards/addCard", createDataCards);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateDataCards = createAsyncThunk(
  "api/updateDataCards",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.put(`/cards/updateCard/${id}`, updatedData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteDataCards = createAsyncThunk(
  "apiQuestion/deleteDataCards",
  async (itemId, thunkAPI) => {
    try {
      await axiosInstace.delete(`/cards/deleteCard/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);

    }
  }
);

const apiCardSlice = createSlice({
  name: "apiCards",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataCard.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataCard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDataCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataCards.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createDataCards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDataCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataCards.fulfilled, (state, action) => {
        const updatedData = action.payload;
        const index = state.data.findIndex((item) => item.id === updatedData.id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.loading = false;
      })
      .addCase(updateDataCards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDataCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataCards.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        state.data = state.data.filter((item) => item.id !== deletedItemId);
        state.loading = false;
      })
      .addCase(deleteDataCards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiCardSlice.reducer;