import React from "react";
import TableCards from "../../components/TableCards/TableCards";

const Cards = () => {
  return (
    <div>
      <div className="m-2 md:m-10 p-2 md:p-6 bg-white rounded-3xl">
        <p className="text-xl mb-4 text-left">Lista e Kartelave</p>
    <TableCards/>
      </div>
    </div>
  );
};

export default Cards;