import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slices/appSlice";
import tableReducer from "./slices/tableSlice";
import deleteModalReducer from "./slices/deleteModalSlice";
import addModalReducer from "./slices/addModalSlice";
import editModalReducer from "./slices/editModalSlice";
import infoModalReducer from "./slices/infoModalSlice";
import apiLoginReducer from "./slices/apiLoginSlice";
import { composeWithDevTools } from "redux-devtools-extension";
import apiCategoryReducer from "./slices/apiCategorySlice";
import apiQuestionReducer from "./slices/apiQuestionSlice";
import authReducer from "./slices/authSlice";
import apiExamsReducer from "./slices/apiExamsSlice";
import apiLiteratureReducer from "./slices/apiLiteratureSlice";
import addModalSubliteratureReducer from "./slices/addModalSubliteratureSlice"
import addModalQuestionCategoryReducer from "./slices/addModalQuestionCategorySlice";
import addModalSubQuestionCategoryReducer from "./slices/addModalSubQuestionCategorySlice";
import apiQuestionCategoryReducer from "./slices/apiQuestionCategorySlice";
import addModalAnswerQuestionCategoryReducer from "./slices/addModalAnswerQuestionCategorySlice";
import editModalExamsReducer from "./slices/editModalExamsSlice";
import editModalQuestionsReducer from "./slices/editModalQuestionsSlice";
import addModalTextLiteratureReducer from "./slices/addModalTextLiteratureSlice";
import deleteModalExamsReducer from "./slices/deleteModalExamsSlice";
import deleteModalQuestionsReducer from "./slices/deleteModalQuestionsSlice";
import editModalLiteratureReducer from "./slices/editModalLiteratureSlice";
import deleteModalLiteratureReduer from "./slices/deleteModalLiteratureSlice";
import editModalQuestionsCategoryReducer from "./slices/editModalQuestionsCategorySlice";
import deleteModalQuestionsCategoryReducer from "./slices/deleteModalQuestionsCategorySlice";
import apiTextLiteratureReducer from "./slices/apiTextliteratureSlice";
import userReducer from "./slices/userSlice";
import apiDrivingSchoolsReducer from "./slices/apiDrivingSchoolsSlice";
import editModalDrivingSchoolsReducer from "./slices/editModalDrivingSchoolsSlice";
import deleteModalDrivingSchoolsReducer from "./slices/deleteModalDrivingSchoolsSlice";
import deleteModalUsersReducer from "./slices/deleteModalUsersSlice";
import deleteModalSubliteratureReducer from "./slices/deleteModalSubliteratureSlice";
import deleteModalTextLiteratureReducer from "./slices/deleteModalTextLiteratureSlice";
import apiPackageReducer from "./slices/apiPackageSlice";
import apiCardReducer from "./slices/apiCardSlice";
import addModalPackageReducer from "./slices/addModalPackageSlice";
import deleteModalPackageReducer from "./slices/deleteModalPackageSlice";
import deleteModalCategoryReducer from "./slices/deleteModalCategorySlice";
import  editModalCategoryReducer from "./slices/editModalCategorySlice";
import apiFinancialBalanceReducer from "./slices/apiFinancialBalanceSlice";
import addModalFinancialBalanceReducer from "./slices/addModalFinancialBalanceSlice";
import editModalFinancialBalanceReducer from "./slices/editModalFinancialBalanceSlice";
import editModalDebtReducer from "./slices/editModalDebtSlice";
import editModalTextLiteratureReducer from "./slices/editModalTextLiteratureSlice";
import editModalSubliteratureReducer from "./slices/editModalSubliteratureSlice";
import editModalPackageReducer from "./slices/editModalPackageSlice";
import getModalBalanceReducer from "./slices/getModalBalanceSlice";
import editModalUsersReducer from "./slices/editModalUsersSlice";
import userdriveschoolSlice from "./slices/userDriveSchoolSlice";
import debtSlice from "./slices/debtSlice";
import paymentSlice from "./slices/paymentSlice";
import deleteModalCardsReducer from "./slices/deleteModalCardsSlice";
import editModalCardsReducer from "./slices/editModalCardsSlice";
import addModalCardsReducer from "./slices/addModalCardsSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    table: tableReducer,
    deleteModal: deleteModalReducer,
    addModalAnswerQuestionCategory: addModalAnswerQuestionCategoryReducer,
    addModalSubQuestionCategory: addModalSubQuestionCategoryReducer,
    addModalQuestionCategory : addModalQuestionCategoryReducer,
    addModalSubliterature: addModalSubliteratureReducer,
    addModalTextLiterature: addModalTextLiteratureReducer,
    addModal: addModalReducer,
    editModal: editModalReducer,
    infoModal: infoModalReducer,
    apiLogin: apiLoginReducer,
    auth: authReducer,
    apiCategory: apiCategoryReducer,
    apiExams: apiExamsReducer,
    apiQuestion: apiQuestionReducer,
    apiLiterature: apiLiteratureReducer,
    apiQuestionCategory: apiQuestionCategoryReducer,
    editModalExams: editModalExamsReducer,
    editModalQuestions: editModalQuestionsReducer,
    deleteModalExams: deleteModalExamsReducer,
    deleteModalQuestions: deleteModalQuestionsReducer,
    editModalLiterature: editModalLiteratureReducer,
    deleteModalLiterature: deleteModalLiteratureReduer,
    editModalQuestionsCategory : editModalQuestionsCategoryReducer,
    deleteModalQuestionsCategory : deleteModalQuestionsCategoryReducer,
    apiTextLiterature : apiTextLiteratureReducer,
    user: userReducer,
    apiDrivingSchools: apiDrivingSchoolsReducer,
    editModalDrivingSchools: editModalDrivingSchoolsReducer,
    deleteModalDrivingSchools: deleteModalDrivingSchoolsReducer,
    deleteModalUsers: deleteModalUsersReducer,
    deleteModalSubliterature : deleteModalSubliteratureReducer,
    deleteModalTextLiterature : deleteModalTextLiteratureReducer,
    apiPackage : apiPackageReducer,
    addModalPackage : addModalPackageReducer,
    deleteModalPackage: deleteModalPackageReducer,
    deleteModalCategory : deleteModalCategoryReducer,
    editModalCategory : editModalCategoryReducer,
    apiFinancialBalance : apiFinancialBalanceReducer,
    addModalFinancialBalance: addModalFinancialBalanceReducer,
    editModalFinancialBalance: editModalFinancialBalanceReducer,
    editModalDebt: editModalDebtReducer,
    editModalTextLiterature: editModalTextLiteratureReducer,
    editModalSubliterature: editModalSubliteratureReducer,
    editModalPackage: editModalPackageReducer,
    getModalBalance: getModalBalanceReducer,
    editModalUsers: editModalUsersReducer,
    userDriveSchoolSlice: userdriveschoolSlice,
    debt: debtSlice,
    payment: paymentSlice,
    apiCards: apiCardReducer,
    deleteModalCardsSlice: deleteModalCardsReducer,
    editModalCards: editModalCardsReducer,
    addModalCards: addModalCardsReducer
  },
  composeWithDevTools,
});

export default store;
