import { FaEuroSign, FaCarRear,FaLayerGroup,FaUsers,FaFileSignature} from 'react-icons/fa6';
import { FaBookReader } from 'react-icons/fa';
import { RiBookReadFill } from 'react-icons/ri'
import { BiSolidRightArrow,BiSolidOffer} from 'react-icons/bi'
import { BsArrowsMove } from 'react-icons/bs'
export const data = [
  { id: 1, name: "Autoshkolla Test ", city: "Prishtinë", date: "2022-01-01", status: "Aktive"},
  { id: 2, name: "Autoshkolla Poligoni", city: "Gjilan", date: "2022-02-01", status: "Pasive"},
  { id: 3, name: "Autoshkolla Instruktori", city: "Prishtinë", date: "2022-03-01", status: "Aktive"},
  { id: 4, name: "Autoshkolla Shoferi", city: "Prizren", date: "2022-03-01", status: "Aktive"},
  { id: 5, name: "Autoshkolla ", city: "Pejë", date: "2022-03-01", status: "Pasive"},
  { id: 6, name: "Autoshkolla Shoferi ", city: "Gjakovë", date: "2022-03-01", status: "Pasive"},
  { id: 7, name: "Autoshkolla Test ", city: "Prishtinë", date: "2022-03-01", status: "Aktive"},
  { id: 8, name: "Autoshkolla Baza ", city: "Gjilan", date: "2022-03-01", status: "Aktive"},
];

export const dataCategories = [
  { id: 1, name: "Kategoria A", date: "2022-01-01", status: "Aktive" },
  { id: 2, name: "Kategoria B", date: "2022-02-01", status: "Pasive" },
  { id: 3, name: "Kategoria C", date: "2022-03-01", status: "Aktive" },
  { id: 4, name: "Kategoria D", date: "2022-03-01", status: "Aktive" },
];

export const dataExams = [
  
    { id: 1, name: "Testi 1", category:"A", date: "2022-01-01", status: "Aktive" },
    { id: 2, name: "Testi 2", category:"B", date: "2022-02-01", status: "Pasive" },
    { id: 3, name: "Testi 3", category:"C", date: "2022-03-01", status: "Pasive" },
    { id: 4, name: "Testi 4", category:"D", date: "2022-03-01", status: "Pasive" },
    { id: 5, name: "Testi 5", category:"A", date: "2022-03-01", status: "Aktive" },
    { id: 6, name: "Testi 6", category:"D", date: "2022-03-01", status: "Aktive" },

];

export const options = [
  { value: '1', label: 'Kategoria A' },
  { value: '2', label: 'Kategoria B' },
  { value: '3', label: 'Kategoria C' },
  { value: '4', label: 'Kategoria D' },

];
export const selectedOptions = [
  { value: '1', label: 'Testi 1' },
  { value: '2', label: 'Testi B' },
  { value: '3', label: 'Testi C' },
  { value: '4', label: 'Testi D' },

];

export const dataQuestions = [
  
  { id: 1, name: "Sinjali në figurë paralajmëron kryqëzim linjash hekurudhore. ", category:"B", Lloji:"Pyetje pa foto", date: "2022-01-01", status: "Aktive" },
  { id: 2, name: "Sinjali në figurë kërkon uljen e shpejtësisë vetëm para kthesës së parë.", category:"B", Lloji:"Pyetje me foto", date: "2022-02-01", status: "Pasive" },
  { id: 3, name: "Shpejtësia e lëvizjes së mjetit duhet ngadalësuar në rrugë me terren të rrëshqitshëm.", category:"B", Lloji:"Pyetje me shenja", date: "2022-03-01", status: "Pasive" },
  { id: 4, name: "Parakalimi në rrugë urbane bëhet duke kaluar shpejtësinë prej 50 km/orë.", category:"B", Lloji:"Pyetje pa foto", date: "2022-03-01", status: "Pasive" },
  { id: 5, name: "Parakalimi në rrugë urbane bëhet duke kaluar shpejtësinë prej 50 km/orë.", category:"B", Lloji:"Pyetje pa foto", date: "2022-03-01", status: "Aktive" },
  { id: 6, name: "Në mjetet me motor shkak për zhurmat mund të jetë dëmtimi i sistemit të shuarjes së zhurmave.  ", category:"B", Lloji:"Pyetje pa foto", date: "2022-03-01", status: "Aktive" },
];

 export const dataFinancialBalance = [
  
  { id: 1, name: "Autoshkolla Test ", city:"Prishtine", bills: "120 €", paid: "Po", debt:"-10", date:"2023-04-18", status: "Aktive"},
  { id: 2, name: "Autoshkolla Poligoni", city:"Prishtine", bills: "120 € ", paid: "Jo", debt:"-10", date:"2023-07-18", status: "Pasive"},
  { id: 3, name: "Autoshkolla Instruktori", city:"Prishtine", bills: "120 € ", paid: "Po", debt:"-10", date:"2023-03-12", status: "Aktive"},
  { id: 4, name: "Autoshkolla Shoferi", city:"Prishtine", bills: "120 € ", paid: "Po", debt:"-10", date:"2023-11-20", status: "Aktive"},
  { id: 5, name: "Autoshkolla ", city:"Prishtine", bills: "120 € ", paid: "Po", debt:"-10", date:"2023-12-20", status: "Pasive"},
  { id: 6, name: "Autoshkolla Shoferi ", city:"Prishtine", bills: "120 € ", paid: "Po", debt:"-10", date:"2023-02-20", status: "Pasive"},

];

export const dataCity = [
    {
      label: "PRISHTINA",
      options: [
        { value: "Prishtinë", label: "Prishtinë" },
        { value: "Podujevë", label: "Podujevë" },
        { value: "Drenas", label: "Drenas" },
        { value: "Fushë Kosovë", label: "Fushë Kosovë" },
        { value: "Graçanicë", label: "Graçanicë" },
        { value: "Lipjan", label: "Lipjan" },
        { value: "Obiliq", label: "Obiliq" },

      ],
    },
    {
      label: "MITROVICË",
      options: [
        { value: "Mitrovicë", label: "Mitrovicë" },
        { value: "Vushtrri", label: "Vushtrri" },
        { value: "Skënderaj", label: "Skënderaj" },
        { value: "Zubin Potok", label: "Zubin Potok" },
        { value: "Leposaviq", label: "Leposaviq" },
        { value: "Zveçan", label: "Zveçan" },

      ],
    },
    {
      label: "Ferizaj",
      options: [
        { value: "Ferizaj", label: "Ferizaj" },
        { value: "Shtime", label: "Shtime" },
        { value: "Kaçanik", label: "Kaçanik" },
        { value: "Shtërpcë", label: "Shtërpcë" },
        { value: "Han I Elezit", label: "Han I Elezit" },
      ],
    },
    {
      label: "GJILAN",
      options: [
        { value: "Gjilan", label: "Gjilan" },
        { value: "Viti", label: "Viti" },
        { value: "Kamenicë", label: "Kamenicë" },
        { value: "Kllokot", label: "Kllokot" },
      ],
    },
    {
      label: "PEJE",
      options: [
        { value: "Pejë", label: "Pejë" },
        { value: "Istog", label: "Istog" },
        { value: "Klinë", label: "Klinë" },
        { value: "Deçan", label: "Deçan" },
        { value: "Junik", label: "Junik" },
      ],
    },
    {
      label: "GJAKOVË",
      options: [
        { value: "Gjakovë", label: "Gjakovë" },
      ],
    },
    {
      label: "PRIZREN",
      options: [
        { value: "Prizren", label: "Prizren" },
        { value: "Dragash", label: "Dragash" },
        { value: "Suharekë", label: "Suharekë" },
        { value: "Malishevë", label: "Malishevë" },
        { value: "Rahovec", label: "Rahovec" },

      ],
    },

]

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'dashboard',
        title: 'dashboard',
        isAdmin: ['admin']
      },
    ],
  },

  {
    title: 'Pages',
    links: [
      {
        name: 'drivingschools',
        title: 'autoshkollat',
        icon: <FaCarRear />,
        isAdmin: ['admin'],
      },
      
      {
        name: 'users',
        title: 'kandidatët',
        icon: <FaUsers />,
        isAdmin: ['admin']
      },
      {
        name: 'movment',
        title: `Aktivizimi i Abonimeve dhe Qarkullimi i Autoshkolles`,
        icon: <BsArrowsMove />,
        isAdmin: ['user']
      },
      {
        name: 'users-driveschool',
        title: 'Kandidatët e Autoshkolles',
        icon: <FaUsers />,
        isAdmin: ['user']
      },
      
      {
        name: 'financialbalance',
        title: 'Barazimi',
        icon: <FaEuroSign  />,
        isAdmin: ['admin'],
      },
      {
        name: 'categories',
        title: 'kategoritë',
        icon: <FaLayerGroup />,
        isAdmin: ['admin'],
      },
      {
        name: 'package',
        title: 'pakot',
        icon: <BiSolidOffer />,
        isAdmin: ['admin'],
      },
      {
        name: 'cards',
        title: 'cards',
        icon: <BiSolidOffer />,
        isAdmin: ['admin'],
      },
      {
        title: 'Krijimi Pyetjeve',
        icon: <BiSolidRightArrow />,
        isDropdown: true,
        isAdmin: ['admin'],
        links: [
          {
            name: 'exams',
            title: 'testet',
            icon: <RiBookReadFill />,
          },
          {
            name: 'questionscategory',
            title: 'pyetjet me kategori',
            icon: <FaFileSignature />,
          },
          {
            name: 'questions',
            title: 'pyetjet',
            icon: <FaBookReader />,
          },
          {
            name: 'question-category',
            title: "Lista e pyetjeve me kategori",
            icon: <FaBookReader />
          }
      ],
    },
      
      {
        title: 'Literatura',
        icon: <BiSolidRightArrow />,
        isDropdown: true,
        isAdmin: ['admin'],
        links: [
          {
            name: 'literature',
            title: 'Literatura',
            icon: <FaBookReader />,
          },
          {
            name: 'textliterature',
            title: 'Teksti',
            icon: <FaFileSignature />,
          },
      ],
    },
  ],
  },
];