import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const editModalCardsSlice = createSlice({
    name: "editModalCards",
    initialState,
    reducers: {
        openModal: (state, action) => {
            const itemId = action.payload;
             state[itemId] = true;
          },
          closeModal: (state, action) => {
            const itemId = action.payload;
            state[itemId] = false;
          },
    },
});

export const { openModal, closeModal } = editModalCardsSlice.actions;
export default editModalCardsSlice.reducer;
