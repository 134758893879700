import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchQuery } from "../../store/slices/tableSlice";
import { SearchBar, Pagination, TableItemsPackage } from '../../components'
import { closeModal } from "../../store/slices/deleteModalSlice";
import { fetchDataPackage } from "../../store/slices/apiPackageSlice";
import AddModalPackage from "../Modals/AddModalPackage";
import { fetchDataCard } from "../../store/slices/apiCardSlice";
import TableItemsCards from "../TableItemsCards/TableItemsCards";
import AddModalCards from "../Modals/AddModalCards";

const TableCards = () => {
  const dispatch = useDispatch();
  const { currentPage, itemsPerPage, searchQuery } = useSelector(
    (state) => state.table
  );
  const dataPackage = useSelector((state) => state.apiCards.data);
  const [searchInput, setSearchInput] = useState("");


  useEffect(() => {
    dispatch(fetchDataCard());
  }, []);

  const filteredData = dataPackage.filter((item) => {
    const code = item.code && item.code.toLowerCase().includes(searchQuery.toLowerCase())
    const catName = item.categoryName && item.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
    return code || catName
  });

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstIndex, lastIndex);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  return (
    <div className="p-3">
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <SearchBar
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchSubmit={handleSearchSubmit}
        />
        <div>
          <button className="text-blue-500 mx-2" alt="you can edit here">
            <AddModalCards
              title="Shto Kodin"
              submitButtonText="Shto Kodin"
              onCancel={handleCloseModal}
              namePackage="Kodi"
              packageCategory="Zgjedh kategorinë"
              priceValue="Cmimi i karteles"
              days="Ditet"
            />
          </button>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
              Kodi
              </th>
              <th scope="col" className="px-6 py-3">
                Kategoria
              </th>
              <th scope="col" className="px-6 py-3">
                Çmimi
              </th>
              <th scope="col" className="px-6 py-3">
                Statusi i Karteles
              </th>
              <th scope="col" className="px-6 py-3">
                Ditet
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <TableItemsCards
              dataPackage={currentItems}
            />
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          filteredData={filteredData}
        />
      </div>
    </div>
  );
};

export default TableCards;


