import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal,closeModal } from "../../store/slices/editModalCardsSlice";
import { closeModalDelete, openModalDelete } from "../../store/slices/deleteModalCardsSlice"
import InfoModal from "../Modals/InfoModal";
import DeleteModalExams from "../Modals/DeleteModal/DeleteModalExams";
import DeleteModalPackage from "../Modals/DeleteModal/DeleteModalPackage";
import DeleteModalCards from "../Modals/DeleteModal/DeleteModalCards";
import EditModalCards from "../Modals/EditModal/EditModalCards";
import { useEffect } from "react";

const TableItemsCards = ({ dataPackage }) => {
  const dispatch = useDispatch();
  const editModalState = useSelector((state) => state.editModalCards); 
  useEffect(() => {
    console.log({ editModalState })
  }, [editModalState])

  const handleCloseModal = (modalType) => {
    if (modalType === "edit") {
      dispatch(closeModal()); 
    } else if (modalType === "delete") {
      dispatch(closeModalDelete()); 
    } else if (modalType === "info") {
      dispatch(closeModalDelete()); 
    }
  };

  const handleOpenModal = (id) => {
    dispatch(openModal(id));
  };

  const handleEditItem = (id) => {
    dispatch(openModal(id));
  };

  const handleDeleteItem = (id) => {
    dispatch(openModalDelete(id));
  };

  function getStatusTextFromBoolean(value) {
    return value ? "E AKTIVIZUAR" : "E PA AKTIVIZUAR";
  }

  
  return (
    <>
      {dataPackage.map((item) => (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.code}
          </td>
          <td className="px-6 py-4">{item.categoryName}</td>
          <td className="px-6 py-4">{item.price}.00 € </td>
          { <td className="px-6 py-4">{getStatusTextFromBoolean(item.status)}</td>   
                  }
                    {/* <td className="px-6 py-4">
            
              <label className="text-white">{getStatusTextFromBoolean(item.status)}</label>
          </td> */}
          <td className="px-6 py-4">{item.days}</td>
          <td className="px-6 py-4 w-40">
            <button className="text-blue-500 mr-2">
              <InfoModal
                title="Info rreth testit"
                name="Emri i testit"
                categoryInInfo="Kategoria e testit"
              />
            </button>
            <button className="text-sucess mx-1" onClick={() => handleEditItem(item.id)}>
              <EditModalCards
                id={item.id}
                title="Edito Një Kartele"
                submitButtonText="Edito kartelen"
                namePackage="Kodi"
                packageCategory="Lloji i kategorisë"
                priceValue="Cmimi i karteles"
                days="Ditet e abonimit"
                isOpen={editModalState[item.id] === true}
              />
            </button>
            <button className="text-danger mx-1" onClick={() => handleDeleteItem(item.id)}>
              <DeleteModalCards 
                id={item.id} 
              />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableItemsCards;





